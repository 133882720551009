<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar color="primary">
        <ion-title>Minha Agenda</ion-title>
        <ion-buttons slot="start" auto-hide="false">
          <ion-menu-button>
            <ion-icon slot="end" :icon="chevronForwardSharp" />
          </ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-loading
      :is-open="isOpenRef"
      message="Carregando informações..."
      :duration="timeout"
      @didDismiss="setOpen(false)"
    />

    <ion-content>
      <ion-segment :value="valueSegment" scrollable>
        <ion-segment-button
          v-for="(item, index) in arrDays"
          :key="index"
          :value="item"
          @click="orderValue(item, index)"
        >
          <ion-label id="segment-label">
            {{ dayjs(item).format("DD/MM") }}
          </ion-label>
        </ion-segment-button>
      </ion-segment>

      <ion-grid>
        <ion-row v-for="(item, index) in arrHours" :key="index">
          <ion-col class="hours" size="2" @click="goToDetailCalendar(item)">
            <ion-label>{{ item.start_time }}</ion-label> <br />
            <ion-label>{{ item.end_time }}</ion-label>
          </ion-col>

          <ion-col class="titles" size="8" @click="goToDetailCalendar(item)">
            <ion-label>{{ item.title }}</ion-label>
          </ion-col>

          <ion-col size="2">
            <ion-icon
              class="icon-star"
              size="large"
              :class="{ gold: item.is_colored }"
              :icon="item.is_colored ? star : starOutline"
              @click="setMySchedule(index)"
            />
          </ion-col>
        </ion-row>
      </ion-grid>

      <div v-if="arrHours.length == 0" class="no-events">
        <ion-label class="label">
          Você não possui nenhuma palestra marcada como favorita nesta data! </ion-label
        ><br />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonLabel,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonLoading,
  IonSegment,
  IonRow,
  IonCol,
  IonGrid,
  IonMenuButton,
  IonIcon,
  IonSegmentButton,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

import { chevronForwardSharp, starOutline, star } from "ionicons/icons";
import dayjs from "dayjs";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MySchedule",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonLabel,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonLoading,
    IonSegment,
    IonRow,
    IonCol,
    IonGrid,
    IonMenuButton,
    IonIcon,
    IonSegmentButton,
  },
  props: {
    timeout: { type: Number, default: 1000 },
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    const router = useRouter();
    return {
      dayjs,
      chevronForwardSharp,
      router,
      starOutline,
      star,
      isOpenRef,
      setOpen,
    };
  },

  data() {
    return {
      items: {},
      arrHours: [],
      arrDays: [],
      valueSegment: null,
    };
  },

  created() {
    const params = this.$route.params.item;
    const parseParams = JSON.parse(params);
    this.items = parseParams;

    this.setOpen(true);

    this.items = parseParams;

    this.items.activity.forEach((element) => {
      this.arrDays.push(element.date); // create array of dates
    });
    this.arrDays = [...new Set(this.arrDays)]; // remove  duplicates dates
    this.orderValue(this.arrDays[0]);
  },

  methods: {
    orderValue(date) {
      this.valueSegment = date;

      if (localStorage.getItem(`${this.items.title}`)) {
        const parseArrFavorites = JSON.parse(
          localStorage.getItem(`${this.items.title}`)
        );

        this.items = parseArrFavorites;

        this.arrHours = [];

        this.items.activity.forEach((element) => {
          if (element.date == date && element.is_colored) {
            this.arrHours.push(element);
          }
        });
      } else {
        this.arrHours = [];

        this.items.activity.forEach((element) => {
          if (element.date == date && element.is_colored) {
            this.arrHours.push(element);
          }
        });
      }
    },
    goToDetailCalendar(item) {
      this.router.push({
        name: "DetailCalendar",
        params: { item: JSON.stringify(item) },
      });
    },

    setMySchedule(index) {
      this.arrHours[index].is_colored = this.arrHours[index].is_colored
        ? false
        : true;

      localStorage.setItem("objEvent", JSON.stringify(this.items));

      localStorage.setItem(`${this.items.title}`, JSON.stringify(this.items));
    },
  },
});
</script>

<style scoped>
ion-list {
  margin-top: 5px;
}

ion-label {
  font-size: 16px;
}

#segment-label {
  font-size: 16px;
  font-weight: bold;
}
.container-hours {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #e8e4e4;
  padding: 20px;
}

.icon-star {
  font-size: 30px;
}

ion-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-segment {
  display: flex;
  justify-content: center;
}

ion-row {
  display: flex;
  flex-wrap: nowrap !important;
}

.titles {
  align-self: center;
  width: 70% !important;
  padding-right: 20px;
  text-transform: uppercase;
}

.hours {
  color: var(--ion-color-primary);
  font-weight: 700;
  width: 15% !important;
  align-self: center;
}

.gold {
  color: goldenrod;
}

.label {
  color: var(--ion-color-medium-shade) !important;
}

.no-events {
  display: grid;
  justify-content: center;
  margin-top: 30px;
  font-weight: bold;
  padding: 25px;
  text-align: center;
}
</style>
