import CalendarEventsVue from '@/views/CalendarEvents.vue';
import DetailCalendarVue from '@/views/DetailCalendar.vue';
import EventsVue from '@/views/Events.vue';
import EventsDetailVue from '@/views/EventsDetail.vue';
import LoginPageVue from '@/views/LoginPage.vue';
import MyScheduleVue from '@/views/MySchedule.vue';
import SpeakerDetailVue from '@/views/SpeakerDetail.vue';
import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [{
        path: "/index.html",
        redirect: "/"
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPageVue
    },
    {
        path: '/events-list',
        name: 'EventsList',
        component: EventsVue,
    },

    {
        path: '/event-detail/:item',
        name: 'EventDetail',
        component: EventsDetailVue,
    },
    {
        path: '/calendar-detail/:item',
        name: 'DetailCalendar',
        component: DetailCalendarVue,
    },
    {
        path: '/event-calendar',
        name: 'EventCalendar',
        component: CalendarEventsVue,
    },
    {
        path: '/speaker-detail/:item',
        name: 'Speaker Detail',
        component: SpeakerDetailVue,
    },
    {
        path: '/my-schedule/:item',
        name: 'MySchedule',
        component: MyScheduleVue,
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router