<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>{{ items?.title }}</ion-title>
        <ion-buttons slot="start" auto-hide="false">
          <ion-menu-button>
            <ion-icon slot="end" :icon="chevronForwardSharp" />
          </ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-card @click="goToCalendarEvents()">
        <ion-card-header>
          <ion-card-title>Informações do Evento</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ion-label>
            <span>Data: </span>
            {{ dayjs(items?.start_date).format("DD/MM/YYYY") }}
          </ion-label>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import { chevronForwardSharp } from "ionicons/icons";

export default defineComponent({
  name: "EventDetail",

  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonMenuButton,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonLabel,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      dayjs,
      chevronForwardSharp,
    };
  },

  data() {
    return {
      items: {},
    };
  },
  created() {
    this.items = JSON.parse(this.$route.params.item);
  },
  methods: {
    goToCalendarEvents() {
      this.router.push("/event-calendar");
    },
  },
});
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-medium);
  font-weight: medium;
  font-size: 18px;
}
.menu-img {
  padding-top: 60px;
  background: #073568;
}
ion-label {
  font-size: 16px;
}

span {
  color: var(--ion-color-primary);
  font-weight: bold;
}

ion-header {
  z-index: 2000;
}
</style>
