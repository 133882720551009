<template>
  <ion-menu
    menu-id="app-menu"
    content-id="main-content"
    @ionDidOpen="openMenu()"
  >
    <ion-content>
      <div class="menu-img">
        <ion-img :src="logo" />

        <ion-list class="bg-list" lines="none">
          <ion-item color="primary" lines="none" @click="goToEventsList()">
            <ion-label v-if="!title"> Carregando ... </ion-label>
            <ion-label v-if="title">
              {{ title }}
            </ion-label>

            <ion-icon slot="end" :icon="chevronForwardSharp" />
          </ion-item>
        </ion-list>
      </div>
      <ion-list lines="none">
        <ion-item @click="goToEventsList()">
          <ion-icon slot="start" :icon="home" />

          <ion-label class="ion-text-wrap"> Início </ion-label>
        </ion-item>
        <ion-item @click="goToCalendarEvents()">
          <ion-icon slot="start" :icon="calendar" />

          <ion-label class="ion-text-wrap"> Agenda </ion-label>
        </ion-item>

        <ion-item @click="goToMySchedule()">
          <ion-icon slot="start" :icon="star" />

          <ion-label class="ion-text-wrap"> Minha Agenda </ion-label>
          <ion-badge slot="end" color="primary">
            {{ arrActivityIsColored.length }}
          </ion-badge>
        </ion-item>

        <ion-item @click="logout()">
          <ion-icon slot="start" color="danger" :icon="logOutOutline" />

          <ion-label class="ion-text-wrap"> Sair </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>
<script>
import {
  IonMenu,
  IonImg,
  IonContent,
  IonLabel,
  IonIcon,
  IonItem,
  IonBadge,
  IonList,
  menuController,
} from "@ionic/vue";
import {
  chevronForwardSharp,
  home,
  calendar,
  logOutOutline,
  star,
} from "ionicons/icons";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Menu",
  components: {
    IonMenu,
    IonImg,
    IonBadge,
    IonContent,
    IonLabel,
    IonIcon,
    IonItem,
    IonList,
  },
  setup() {
    const router = useRouter();
    return {
      home,
      calendar,
      chevronForwardSharp,
      router,
      star,
      logOutOutline,
      logo: require("../assets/abrafarma-menu.png"),
    };
  },

  data() {
    return {
      messages: [],
      subscription: null,
      title: "",
      subscriptionTitle: null,
      countBadge: 0,
      arrActivityIsColored: [],
      newArrFavorites: {},
    };
  },

  methods: {
    goToEventsList() {
      menuController.close("app-menu");
      this.router.push("/events-list");
    },
    goToCalendarEvents() {
      menuController.close("app-menu");
      this.router.push("/event-calendar");
    },

    logout() {
      menuController.close("app-menu");

      localStorage.clear();
      this.router.push("/");
    },

    goToMySchedule() {
      menuController.close("app-menu");

      this.router.push({
        name: "MySchedule",
        params: { item: JSON.stringify(this.newArrFavorites) },
      });
    },
    openMenu() {
      if (localStorage.getItem("nameEvent")) {
        this.title = localStorage.getItem("nameEvent");

        if (localStorage.getItem(`${this.title}`)) {
          const getEvent = localStorage.getItem(`${this.title}`);

          this.newArrFavorites = JSON.parse(getEvent);

          if (this.newArrFavorites) {
            this.arrActivityIsColored = this.newArrFavorites?.activity?.filter(
              (item) => item.is_colored
            );
          }
        }
      }
    },
  },
});
</script>
<style scoped>
ion-label {
  font-size: 18px !important;
}

ion-img {
  padding-top: 45px;
  padding-bottom: 15px;
}

.bg-list {
  background: var(--ion-color-primary);
}

.menu-img {
  background: var(--ion-color-primary);
}
ion-icon {
  margin-right: 15px;
  color: var(--ion-color-medium);
}
</style>
