<template>
  <ion-page>
    <ion-content
      class="ion-padding"
      :fullscreen="true"
    >
      <div class="fullheight xc">
        <div class="login-container">
          <div id="container">
            <ion-img :src="logo" />
          </div>

          <ion-item>
            <ion-label position="floating">
              CPF
            </ion-label>
            <ion-input
              v-model.lazy="cpfValue"
              maxlength="11"
              placeholder="Digite seu CPF"
            />
          </ion-item>
        </div>
      </div>

      <ion-button
        :disabled="isDisableButton"
        expand="full"
        shape="round"
        @click.prevent="onLogin()"
      >
        Entrar
      </ion-button>
    </ion-content>

    <ion-footer class="ion-no-border">
      <div class="footer">
        <p>v1.0.002</p>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonLabel,
  IonPage,
  IonInput,
  IonImg,
  IonItem,
  IonButton,
  IonFooter,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";

import api from "../api";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "LoginPage",
  components: {
    IonContent,
    IonLabel,
    IonPage,
    IonInput,
    IonImg,
    IonItem,
    IonButton,
    IonFooter
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      cpfValue: "",
      isDisableButton: true,
      logo: require("../assets/abrafarma.png"),
    };
  },
  watch: {
    cpfValue: function (value) {
      this.isDisableButton = value.length < 11 ? true : false;
    },
  },
  methods: {
    onLogin() {
      api
        .post("login", {
          document: this.cpfValue,
          device: "467b7bd055772429",
          platform: "Android",
        })
        .then(({ data }) => {
          localStorage.setItem("token", data.token);
          this.router.push("/events-list");
        })
        .catch((error) => {
          console.log("cai no erro");
          this.presentToast("top", "Dados inválidos, tente novamente!");
          console.log(error);
        });
    },

    async presentToast(position, message) {
      const toast = await toastController.create({
        message: message,
        duration: 2500,
        color: "danger",
        position: position,
      });

      await toast.present();
    },
  },
});
</script>

<style scoped>
#container {
  display: flex;
  padding-top: 20px;
  justify-content: center;
  padding-bottom: 45px;
}

.login-container {
  width: 90%;
}

.footer {
  display: flex;
  justify-content: center;
}

ion-img {
  width: 250px;
}

ion-item {
  display: flex;
  justify-content: center;
}

.fullheight {
  height: 270px;
}

.xc {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
