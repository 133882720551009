<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar color="primary">
        <ion-title>Seus Eventos</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list>
        <ion-item
          v-for="(item, index) in events2023"
          :key="index"
          @click="goToDetail(item)"
        >
          <ion-avatar slot="start">
            <img :src="item.logo" />
          </ion-avatar>

          <ion-label class="ion-text-wrap">
            {{ item?.title }}<br />
            {{ dayjs(item.start_date).format("DD/MM/YYYY") }} -
            {{ dayjs(item.end_date).format("DD/MM/YYYY") }}
          </ion-label>

          <ion-icon slot="end" :icon="chevronForwardSharp" />
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonIcon,
  IonPage,
  IonItem,
  IonLabel,
  IonAvatar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import api from "../api";
import { chevronForwardSharp } from "ionicons/icons";
import dayjs from "dayjs";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "EventsList",
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonIcon,
    IonPage,
    IonItem,
    IonLabel,
    IonAvatar,
  },

  setup() {
    const router = useRouter();
    return {
      dayjs,
      chevronForwardSharp,
      router,
    };
  },

  data() {
    return {
      events: {},
      events2023: {},
    };
  },
  created() {
    this.getEvents();
  },

  methods: {
    getEvents() {
      api
        .get("event")
        .then((res) => {
          this.events = res?.data;
          this.events2023 = this.events.filter((event) => {
            const eventDate = new Date(event.start_date);
            return eventDate.getFullYear() === 2023;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async goToDetail(item) {
      await localStorage.setItem("objEvent", JSON.stringify(item));
      await localStorage.setItem("nameEvent", item.title);

      this.router.push({
        name: "EventDetail",
        params: { item: JSON.stringify(item) },
      });
    },
  },
});
</script>

<style scoped>
ion-list {
  margin-top: 5px;
}

ion-label {
  white-space: nowrap !important;
  overflow: hidden;
  color: var(--ion-color-dark-tint) !important;
  text-overflow: ellipsis;
}
</style>
