<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Palestra</ion-title>
        <ion-buttons slot="start" auto-hide="false">
          <ion-back-button />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="container">
        <p class="title">
          {{ itemActivity.title }}
        </p>

        <p>
          {{ itemActivity.place }}
        </p>

        <p>
          {{ dayjs(itemActivity.date).format("DD/MM") }} -
          {{ itemActivity.start_time }} - {{ itemActivity.end_time }}
        </p>
      </div>

      <ion-row>
        <h4>Palestrantes</h4>
      </ion-row>

      <div v-if="arrAllSpeakers.length == 0" class="no-speakers">
        <ion-label>Não há palestrantes!</ion-label>
      </div>

      <ion-list lines="none">
        <ion-item
          v-for="(item, index) in arrAllSpeakers"
          :key="index"
          @click="getSpeakerClick(item, index)"
        >
          <ion-avatar slot="start">
            <img :src="item.avatar" />
          </ion-avatar>

          <ion-label class="ion-text-wrap">
            {{ item?.name }}<br />
            {{ item?.note }}
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonContent,
  IonRow,
  IonLabel,
  IonList,
  IonItem,
  IonAvatar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import api from "../api";

import dayjs from "dayjs";
export default defineComponent({
  name: "DetailCalendar",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonContent,
    IonRow,
    IonLabel,
    IonList,
    IonItem,
    IonAvatar,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      dayjs,
    };
  },

  data() {
    return {
      arrAllSpeakers: [],
      itemActivity: {},
      objEvent: [],
      arrActivities: [],
      allSpeakers: [],
    };
  },

  created() {
    const params = this.$route.params.item;
    this.itemActivity = JSON.parse(params);
    this.objEvent = JSON.parse(localStorage.getItem("objEvent"));

    this.objEvent.activity.forEach((element) => {
      this.arrActivities.push(element);
    });

    this.getSpeakers();
  },

  methods: {
    getSpeakers() {
      api
        .get("speaker")
        .then((res) => {
          this.allSpeakers = res?.data;

          this.allSpeakers.forEach((allSpeakers) => {
            this.itemActivity.speakers.forEach((speaker) => {
              if (allSpeakers.id === speaker) {
                this.arrAllSpeakers.push(allSpeakers);
              }
            });
          });

          this.arrAllSpeakers.sort((a, b) => a.name.localeCompare(b.name));
          console.log("co", this.arrAllSpeakers);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSpeakerClick(speaker) {
      const data = { infoSpeaker: speaker, activities: this.arrActivities };

      this.router.push({
        name: "Speaker Detail",
        params: { item: JSON.stringify(data) },
      });
    },
  },
});
</script>

<style scoped>
.container {
  background: #01a470;
  padding: 20px;
  display: block;
}

ion-row {
  background: #f8f8f8;
  padding: 9px;
  height: 67px;
  display: flex;
  align-self: center;
}

h4 {
  margin-top: 10px;
  margin-left: 10px;
  color: var(--ion-color-medium-shade);
}

p {
  font-size: 18px;

  color: white !important;
}

ion-label {
  font-size: 18px !important;
  color: var(--ion-color-medium-shade) !important;
}

.title {
  text-transform: uppercase;
}

.ion-text-wrap {
  white-space: nowrap !important;
}

.no-speakers {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-weight: bold;
}
</style>
