<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Palestrante</ion-title>
        <ion-buttons slot="start" auto-hide="false">
          <ion-back-button />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="container">
        <div class="img-avatar">
          <ion-avatar>
            <img :src="itemSpeaker.infoSpeaker.avatar" />
          </ion-avatar>
        </div>

        <div class="container-notes">
          <ion-label class="label-speaker">
            {{ itemSpeaker.infoSpeaker.name }}
          </ion-label>
          <ion-label class="label-speaker">
            {{ itemSpeaker.infoSpeaker.position }}
          </ion-label>
        </div>
      </div>

      <ion-row>
        <ion-text>{{ itemSpeaker.infoSpeaker.note }}</ion-text>
      </ion-row>
      <ion-row class="row-speaker">
        <h4>Palestras</h4>
      </ion-row>

      <ion-grid>
        <ion-row v-for="(item, index) in arrActivityToSpeaker" :key="index">
          <ion-col class="hours" size="2" @click="goToDetailCalendar(item)">
            <ion-label class="label-hours">
              {{ item.start_time }}
            </ion-label>
            <br />
            <ion-label class="label-hours">
              {{ item.end_time }}
            </ion-label>
          </ion-col>

          <ion-col class="titles" size="8" @click="goToDetailCalendar(item)">
            <ion-label>{{ item.title }}</ion-label>
          </ion-col>

          <ion-col size="2">
            <ion-icon
              class="icon-star"
              size="large"
              :class="{ gold: item.is_colored }"
              :icon="item.is_colored ? star : starOutline"
              @click="setMySchedule(item, index)"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonContent,
  IonGrid,
  IonCol,
  IonText,
  IonRow,
  IonLabel,
  IonAvatar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { chevronForwardSharp, starOutline, star } from "ionicons/icons";

import dayjs from "dayjs";
export default defineComponent({
  name: "SpeakerDetail",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonContent,
    IonGrid,
    IonCol,
    IonText,
    IonRow,
    IonLabel,
    IonAvatar,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      dayjs,
      chevronForwardSharp,
      starOutline,
      star,
    };
  },

  data() {
    return {
      itemSpeaker: {},
      arrActivityToSpeaker: [],
      arrSpeakersOrder: [],
    };
  },

  created() {
    const params = this.$route.params.item;
    this.itemSpeaker = JSON.parse(params);

    this.itemSpeaker.activities.forEach((elActivies) => {
      elActivies.speakers.forEach((elSpeakers) => {
        if (elSpeakers === this.itemSpeaker.infoSpeaker.id) {
          this.arrActivityToSpeaker.push(elActivies);
        }
      });
    });

    this.arrActivityToSpeaker.sort(function (a, b) {
      return a.start_time.localeCompare(b.start_time);
    });
  },

  methods: {
    setMySchedule(activity, index) {
      this.arrActivityToSpeaker[index].is_colored = this.arrActivityToSpeaker[
        index
      ].is_colored
        ? false
        : true; //set color in array responsible to vue-for

      const nameEvent = localStorage.getItem("nameEvent"); //get name event to find localstorage based in name event
      const arrEvent = localStorage.getItem(`${nameEvent}`); // get arr event based in name event dynamic
      const arrEventParse = JSON.parse(arrEvent); // convert string in parse

      const findIndex = arrEventParse.activity.findIndex(
        (item) => item.id == activity.id // findIndex to find position arrEvent is colored
      );

      arrEventParse.activity[findIndex].is_colored = arrEventParse.activity[
        findIndex
      ].is_colored
        ? false
        : true; // set is colored in array

      localStorage.setItem("objEvent", JSON.stringify(arrEventParse));

      localStorage.setItem(
        `${arrEventParse.title}`,
        JSON.stringify(arrEventParse)
      );
    },

    goToDetailCalendar(item) {
      this.router.push({
        name: "DetailCalendar",
        params: { item: JSON.stringify(item) },
      });
    },
  },
});
</script>

<style scoped>
.container {
  background: #01a470;
  padding: 20px;
  display: block;
}

.img-avatar {
  display: flex;
  justify-content: center;
}

ion-col {
  align-self: center;
}

.container-notes {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.row-speaker {
  background: #f8f8f8;
  padding: 9px;
  height: 67px;
  display: flex;
  align-self: center;
}

h4 {
  margin-top: 10px;
  margin-left: 10px;
  color: var(--ion-color-medium-shade);
}

p {
  font-size: 18px;

  color: white !important;
}

ion-label {
  font-size: 18px !important;
}

.title {
  text-transform: uppercase;
}
.label-speaker:first-child {
  color: white !important;
  margin-top: 5px;
  font-size: 24px !important;
}

.label-speaker {
  color: white !important;
  margin-top: 5px;
}

.ion-text-wrap {
  white-space: nowrap !important;
}

ion-text {
  color: var(--ion-color-dark) !important;
  font-size: 15px;

  padding: 25px;
}

.no-speakers {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-weight: bold;
}
.titles {
  align-self: center;
  width: 70% !important;
  padding-right: 20px;
  text-transform: uppercase;
}

.hours {
  font-weight: 700;
  width: 15% !important;
  align-self: center;
}

.icon-star {
  font-size: 30px;
}

.label-hours {
  color: var(--ion-color-primary) !important;
}

.gold {
  color: goldenrod !important;
}
</style>
